.primary {
    position: relative;
    min-height: 500px;
    max-height: 780px;

    @include max-w-xs {
        max-height: 745px;
    }

    &.artist {
        &::before {
            @include max-w-xs {
                padding-top: 0%;
            }
        }
    }

    &::before {
        @include max-w-xs {
            padding-top: 219%;
        }

        content: "";
        padding-top: 54%;
        display: block;
    }

    &-container {
        margin-top: 246px;

        @include max-w-md {
            margin-top: 150px;
        }

        @include max-w-sm {
            margin-top: 114px;
        }
    }

    &__holder {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        @include max-w-xs {
            &::before {
                content: "";
                position: absolute;
                display: block;
                width: 100%;
                height: 45%;
                min-height: 432px;
                background: #f1f2fa;
                transform: skewY(-5deg) translateY(-22px);
                border-bottom-left-radius: 20px;
                border-bottom-right-radius: 20px;
            }
        }

        @include max-w-2xs {
            &::before {
                content: "";
                position: absolute;
                display: block;
                width: 100%;
                height: 45%;
                background: #f1f2fa;
            }
        }

        @media (max-width: 340px) {
            &::before {
                height: 75%;
            }
        }
    }

    &__content {
        @include max-w-xs {
            padding-bottom: 2.5em;
        }

        padding-bottom: 3.75em;
        position: relative;
        z-index: 5;
    }

    &::after {
        content: "";
        position: absolute;
        pointer-events: none;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 8em;
    }

    &__video {
        &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            content: "";
            background-image:
            linear-gradient(
                88.55deg,
                #f1f2fa -10.21%,
                rgba(241, 242, 250, 0.83) 28.49%,
                rgba(241, 242, 250, 0) 53.87%
            );
            transform: skewY(-2deg) translateY(-22px);
            border-bottom-left-radius: 20px;
            border-bottom-right-radius: 20px;

            @include max-w-md {
                display: none;
            }
        }
    }

    &__pic {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        object-fit: cover;
        transform: skewY(-2deg) translateY(-22px);

        @include max-w-xs {
            // object-position: -770px 200px;
            // margin-top: 50px;

            &.home {
                transform: skewY(-5deg) translateY(-22px);
                object-position: -440px 90px;
                margin-top: 250px;
                height: 70%;
            }
        }
        // & > img {
        //     width: 100%;
        //     height: 100%;
        //     object-fit: cover;
        //     overflow: hidden;
        // }
    }
}

.solution {
    & .primary {
        max-height: 526px;

        &::before {
            @include max-w-xs {
                padding-top: 40%;
            }

            content: "";
            padding-top: 40%;
            display: block;
        }

        &__pic {
            width: 100%;
            height: 100%;
            // border-radius: 40px;
            overflow: hidden;
            clip-path: none;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            transform: skewY(0deg) translateY(0);

            & > img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: bottom;
                // border-radius: 40px;
                overflow: hidden;
            }
        }

        &__holder {
            &::before {
                background: none;
            }
        }
    }

    .primary-container {
        margin-top: 155px;

        @include max-w-xs {
            margin-top: 114px;
        }
    }
}

.our-story {
    & .primary {
        min-height: 400px;

        &::after {
            content: "";
            height: 0;
        }

        &::before {
            @include max-w-xs {
                padding-top: 0;
            }

            content: "";
            padding-top: 0;
            display: block;
        }

        &__pic {
            width: 100%;
            height: 100%;
            // border-radius: 40px;
            overflow: hidden;
            clip-path: none;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            transform: skewY(0deg) translateY(0);

            & > img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                // border-radius: 40px;
                overflow: hidden;
                object-position: bottom;

                @media (min-width: 1440px) {
                    object-position: center;
                }
            }
        }

        &__holder {
            &::before {
                background: none;
            }
        }
    }
}

.careers {
    & .primary {
        min-height: 660px;
        max-height: 660px;

        @media (max-width: 1133px) {
            min-height: 1000px;
        }

        @include max-w-sm {
            min-height: 1100px;
        }

        &__content {
            padding-bottom: 40px;
        }

        &-container {
            margin-top: 155px;

            @include max-w-sm {
                margin-top: 114px;
            }

            @media (max-width: 1133px) {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
            }
        }

        &::after {
            content: "";
            height: 0;
        }

        &::before {
            @media (max-width: 1133px) {
                padding-top: 100%;
            }

            content: "";
            padding-top: 50%;
            display: block;
        }

        &__right-content {
            padding: 50px;
            background: $white-100;
            max-height: 306px;
            border-radius: 20px;
            // margin-top: 24.5px;

            @include max-w-sm {
                padding: 25px;
                max-height: max-content;
            }

            & strong {
                font-size: 16px;
                font-weight: 600;
                color: #3f2d54;
            }
        }

        &__careers-icon {
            padding: 5.5px;
            background: $white-100;
            box-shadow: 0 5px 10px rgba(125, 20, 236, 0.05);
            border-radius: 6px;
            width: 26px;
            height: 26px;
        }

        &__careers-icon-text {
            letter-spacing: 2px;
            text-transform: uppercase;
            font-weight: 600;
            font-size: 12px;
            line-height: 100%;
            color: $text-black-100;
        }

        &__pic {
            width: 100%;
            transform: skewY(0deg) translateY(0);

            @include max-w-sm {
                transform: skewY(0deg) translateY(0);
                margin-top: 0;
            }
            // border-radius: 40px;
            overflow: hidden;
            clip-path: none;

            & > img {
                width: 100%;
                height: 100%;
                object-position: bottom;
                // border-radius: 40px;
                overflow: hidden;
            }
        }

        &__holder {
            &::before {
                background: none;
            }
        }
    }
}

.initiatives {
    & .primary {
        min-height: 829px;

        @include max-w-1096 {
            min-height: 1380px;
        }

        @include max-w-sm {
            min-height: 1374px;
        }

        &__holder {
            margin-top: 155px;
            height: auto;

            &::before {
                background: none;
            }

            @include max-w-sm {
                margin-top: 114px;
            }

            @media (max-width: 1133px) {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
            }
        }

        &::after {
            content: "";
            height: 0;
        }

        &::before {
            @media (max-width: 845px) {
                padding-top: 100%;
            }

            content: "";
            padding-top: 50%;
            display: block;
        }

        &__right-content {
            padding: 50px;
            background: $white-100;
            max-height: 306px;
            border-radius: 20px;

            @include max-w-sm {
                padding: 25px;
                max-height: max-content;
            }

            & strong {
                font-size: 16px;
                font-weight: 600;
                color: #3f2d54;
            }
        }

        &__careers-icon {
            padding: 5.5px;
            background: $white-100;
            box-shadow: 0 5px 10px rgba(125, 20, 236, 0.05);
            border-radius: 6px;
            width: 26px;
            height: 26px;
        }

        &__careers-icon-text {
            letter-spacing: 2px;
            text-transform: uppercase;
            font-weight: 600;
            font-size: 12px;
            line-height: 100%;
            color: $text-black-100;
        }

        &__pic {
            width: 100%;
            transform: skewY(0deg) translateY(0);

            @include max-w-sm {
                margin-top: 0;
            }
            // border-radius: 40px;
            overflow: hidden;
            clip-path: none;

            & > img {
                width: 100%;
                height: 100%;
                // border-radius: 40px;
                overflow: hidden;
            }
        }
    }
}

.social {
    & .primary {
        min-height: 936px;

        @media (max-width: 845px) {
            min-height: 1250px;
        }

        &::after {
            content: "";
            height: 0;
        }

        &::before {
            @media (max-width: 845px) {
                padding-top: 100%;
            }

            content: "";
            padding-top: 50%;
            display: block;
        }

        &__pic {
            width: 100%;

            @include max-w-sm {
                margin-top: 0;
            }

            overflow: hidden;
            clip-path: none;
            transform: skewY(0) translateY(0);

            & > img {
                width: 100%;
                height: 100%;
                overflow: hidden;
            }
        }

        &__holder {
            margin-top: 155px;
            height: auto;

            @include max-w-md {
                margin-top: 124px;
            }

            &::before {
                background: none;
            }
        }
    }
}

.blog {
    & .primary {
        max-height: 462px;

        @media (max-width: 845px) {
            max-height: 414px;
            min-height: 414px;
        }

        // @include max-w-sm {
        //     max-height: 345px;
        // }

        &::after {
            content: "";
            height: 0;
        }

        &::before {
            @media (max-width: 845px) {
                padding-top: 100%;
            }

            content: "";
            padding-top: 50%;
            display: block;
        }

        &__pic {
            width: 100%;
            transform: skewY(0) translateY(0);

            @include max-w-sm {
                margin-top: 0;
            }

            overflow: hidden;
            clip-path: none;

            & > img {
                width: 100%;
                height: 100%;
                overflow: hidden;
            }
        }

        &__holder {
            margin-top: 185px;
            height: auto;

            @include max-w-md {
                margin-top: 124px;
            }

            &::before {
                background: none;
            }
        }
    }
}

.logos {
    &__images {
        row-gap: 32px;

        @include max-w-1096 {
            display: grid;
            grid-template-columns: 0.33fr 0.33fr 0.33fr;
            grid-template-rows: 0.5fr 0.5fr;

            & > img {
                position: relative;
                left: 50%;
                transform: translateX(-50%);
            }
            // grid-column-gap: 20px;
        }

        @include max-w-sm {
            display: grid;
            grid-template-columns: 0.5fr 0.5fr;
            grid-template-rows: 0.5fr 0.5fr;
            // grid-column-gap: 20px;
        }

        @include max-w-2xs {
            justify-content: center;
        }

        & > img {
            width: 174px;
            height: 50px;
            object-fit: contain;

            @include max-w-xs {
                width: 157px;
                height: 30px;
            }

            @include max-w-2xs {
                width: 164px;
                height: 40px;
            }
        }
    }
}
